<template>
  <div>
    <v-snackbar v-model="snackbar" :top="true" :color="color" :timeout="6000">
      <span v-if="snackbar_msg != null">{{ snackbar_msg }}</span>
      <v-btn dark text @click="snackbar = false">Close</v-btn>
    </v-snackbar>
    <v-row>
            <div style="position: fixed; z-index: 1;  background-color: white; width: 100%; top:55px;">
            <v-breadcrumbs :items="items" large style=" font-weight: 600; letter-spacing: 1px; ">

            </v-breadcrumbs>
            </div>
        </v-row><br><br><br />
    <section class="bg-color-">
      <div class="container">
        <template>
          <div v-if="init_loading">
          <v-skeleton-loader v-bind="attrs" type="three-line,  article"></v-skeleton-loader>
        </div>
        <div v-else>
          <v-card>
            <v-card-title>
              <v-text-field v-model="search" append-icon="mdi-magnify" label="Search" single-line
                hide-details></v-text-field>
              <v-spacer></v-spacer>
              <template>
                <download-excel :data="data_list" :fields="all_" worksheet="Book Donation Request"
                  name="Book Donation Request.xls">
                  <v-tooltip bottom>
                    <template v-slot:activator="{ on, attrs }">
                      <v-icon color="green" size="40" left v-bind="attrs" v-on="on">mdi-file-excel</v-icon>
                    </template>
                    <span>Import to Excel</span>
                  </v-tooltip>
                </download-excel>
              </template>
            </v-card-title>

            
            <v-data-table :headers="headers" :items="data_list" :search="search">
              <template v-slot:item.totalbooksDonation="{ item }">
                <v-tooltip bottom>
                  <template v-slot:activator="{ on, attrs }">
                    <v-chip color="gray" v-bind="attrs" v-on="on" dark @click="getdetails(item)">
                      {{ item.totalbooksDonation }}
                    </v-chip>
                  </template>
                  <span> View Details</span>
                </v-tooltip>
              </template>
            </v-data-table>
            
          </v-card>
        </div>
        </template>
      </div>
    </section>
    <!-- /. section -->
    <!-- details -->
    <template>
      <v-row justify="center">
        <v-dialog v-model="dialog_details" fullscreen hide-overlay transition="dialog-bottom-transition">
          <v-card>
            <v-toolbar dark color="gray">
              <v-btn icon dark @click="dialog_details = false">
                <v-icon>mdi-close</v-icon>
              </v-btn>
              <v-toolbar-title>{{ org_name }}</v-toolbar-title>
              <v-spacer></v-spacer>
              <v-toolbar-items>
                <v-btn dark text @click="dialog_details = false">
                  Close
                </v-btn>
              </v-toolbar-items>
            </v-toolbar>
            <br />
            <v-card-text>
              <v-card>
                <v-card-title>
                  <v-text-field v-model="search_" append-icon="mdi-magnify" label="Search" single-line
                    hide-details></v-text-field>
                  <v-spacer></v-spacer>
                  <template>
                    <download-excel :data="details_list" :fields="single_" worksheet="Book Donation Request"
                      name="Book Donation Request Data.xls">
                      <v-tooltip bottom>
                        <template v-slot:activator="{ on, attrs }">
                          <v-icon color="green" size="40" left v-bind="attrs" v-on="on">mdi-file-excel</v-icon>
                        </template>
                        <span>Import to Excel</span>
                      </v-tooltip>
                    </download-excel>
                  </template>
                </v-card-title>
                <div v-if="loader">
          <v-skeleton-loader v-bind="attrs" type="three-line,  article"></v-skeleton-loader>
        </div>
        <div v-else>
                <v-data-table :headers="headers_" :items="details_list" :search="search_">
                </v-data-table>
                </div>
              </v-card>
            </v-card-text>
          </v-card>
        </v-dialog>
      </v-row>
    </template>
  </div>
  <!-- /. root div -->
</template>
<script>
import axios from "axios";
export default {
  components: {},
  data: () => ({
    init_loading: false,
    loader: false,
    data_list: [],
    details_list: [],
    snackbar_msg: "",
    color: "",
    snackbar: false,
    search: "",
    search_: "",
    org_name: null,
    dialog_details: false,
    items: [
      {
        text: 'Dashboard',
        disabled: false,
        href: '/home',
      },
      {
        text: localStorage.getItem("jhhjbyj"),
        disabled: false,
        href: '/sublink',
      },
      {
        text: 'Book Donations Request',
        disabled: true,
        href: '',
      },
    ],
    headers: [
      { text: "Sr. No.", value: "srno" },
      { text: "Organization", value: "orgName" },
      { text: "Number of Book Donation Request", value: "totalbooksDonation" },
    ],
    headers_: [
      { text: "Sr. No.", value: "srno" },
      { text: "Organization", value: "orgName" },
      { text: "Name", value: "name" },
      { text: "User", value: "user" },
      { text: "User Type", value: "user_type" },
      { text: "Book Condition", value: "book_condition" },
      { text: "Request Date", value: "request_date" },
      { text: "Probable Delivery Date", value: "delivery_date" },
      { text: "No. of Copies", value: "copys" },
    ],
    single_: {
      "Sr. No.": "srno",
      Orgnization: "orgName",
      Name: "name",
      User: "user",
      " User Type": "user_type",
      " Book Condition": "book_condition",
      " Request Date": "request_date",
      "Probable Delivery Date": "delivery_date",
      "No. of Copies": "copys",
    },
    all_: {
      "Sr. No.": "srno",
      Orgnization: "orgName",
      "Number of Book Donation Request": "totalbooksDonation",
    },
  }),
  mounted() {
    this.onLoad();
  },
  methods: {
    onLoad() {
      this.init_loading = true;
      axios
        .post("/Management/getAllDonationData")
        .then((res) => {
          if (res.data.msg == "200") {
            this.data_list = res.data.bookDonationRequestlist;
            this.init_loading = false;
          }
        })
        .catch((error) => {
          this.showSnackbar("#b71c1c", "Something went wrong..."); // show snackbar
          window.console.log(error);
        });
    },
    getdetails(item) {
      const data = {
        orgid: item.orgid,
      };
      this.loader = true;
      axios
        .post("/Management/getDonationDetails", data)
        .then((res) => {
          if (res.data.msg == "200") {
            this.details_list = res.data.requestdate;
            this.org_name = res.data.orgName;
            this.dialog_details = true;
            this.loader = false;
          }
        })
        .catch((error) => {
          this.showSnackbar("#b71c1c", "Something went wrong..."); // show snackbar
          window.console.log(error);
        });
    },
    showSnackbar(clr, msg) {
      this.snackbar = true;
      this.color = clr;
      this.snackbar_msg = msg;
    },
  },
  watch: {},
};
</script>
<style scoped>
@media (min-width: 320px) and (max-width: 767.98px) {}

@media (min-width: 768px) and (max-width: 991.98px) {}

@media (min-width: 992px) and (max-width: 1024px) {}
</style>
